

// Navbar.js
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './Navbar.css';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav className={`navbar ${isOpen ? 'open' : ''}`}>
            <div className="menu-toggle" onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>
            <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                <li>
                    <Link to="/" onClick={closeMenu}>Home</Link>
                </li>
                <li>
                    <Link to="/our-business" onClick={closeMenu}>Our Business</Link>
                </li>
                <li>
                    <Link to="/properties" onClick={closeMenu}>Properties</Link>
                </li>
                <li>
                    <Link to="/finance" onClick={closeMenu}>Finance</Link>
                </li>
                <li>
                    <Link to="/legal" onClick={closeMenu}>Legal</Link>
                </li>
                <li>
                    <Link to="/market-updates" onClick={closeMenu}>Market Updates</Link>
                </li>
                <li>
                    <Link to="/contact" onClick={closeMenu}>Contact</Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;

