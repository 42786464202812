// EnquiryForm.js
import React, { useState } from 'react';
import './EnquiryForm.css';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import emailjs from 'emailjs-com';

function EnquiryForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple validation
        if (!name.trim() || !email.trim() || !phone.trim() || !message.trim()) {
            alert('Please fill in all fields.');
            return;
        }

        e.preventDefault();

        emailjs.sendForm('service_zqgy6i6', 'template_j2p8w12', e.target, '14vbN4RymCWDu7zWf')
            .then((result) => {
                console.log(result.text);
                alert('Email sent successfully!');
                e.target.reset(); // Reset the form after successful submission
            }, (error) => {
                console.error(error.text);
                alert('Error sending email. Please try again later.');
            });

        // Clear form after submission
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
    };

    return (
        <div className="enquiry-form">
            <h1>Enquiries</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Name:

                </label>
                <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <label>
                    Email:

                </label>
                <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label>
                    Phone Number:

                </label>
                <PhoneInput
                    defaultCountry="gb"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    className="phone-input"
                />
                <label>
                    Message:
                </label>
                <textarea
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <input type="submit" value="Submit" />
            </form>
        </div>
    );
}

export default EnquiryForm;
