import React from "react";
import './ArticleStyles.css';
function FreeHoldsPage() {
    return (
        <div>
            <div className="article-container">
                <h2 className="article-title">The Benefits of Buying a Business</h2>
                <p className="article-meta">Published on January 1, 2024</p>
                <img
                    className="article-image"
                    // src="https://www.shutterstock.com/shutterstock/photos/557307181/display_1500/stock-photo-businessmen-making-handshake-in-the-city-business-etiquette-congratulation-merger-and-557307181.jpg" // Replace with your actual image URL
                    src="https://www.shutterstock.com/shutterstock/photos/246420013/display_1500/stock-photo-harbor-freight-blue-toned-images-246420013.jpg"
                    alt="Article Image"
                />
                <p className="article-content">
                    Buying a business as a going concern, especially when it includes property interests and assets , can offer several advantages. Here are some of the key benefits:
                </p>
                <ol className="article-content">
                    <li>
                        You acquire an Established Operation with existing customers,
                        suppliers, and employees. This is an obvious advantage over starting a business from scratch.
                    </li>
                    <li>Access to immediate Cash Flow and Profits  This can help you
                        recoup your investment faster and reduce the timescales to achieve profitability.
                    </li>
                    <li>
                        *Access to Existing Infrastructure*: The business will
                        usually have existing infrastructure and assets such as equipment, facilities, and technology, in place.  .
                    </li>
                    <li>
                        *Benefit From an established Brand and Reputation*:
                        This can help increase your customer base and  provide a foundation for growth and expansion.
                    </li>
                    <li>
                        *Property Interests*: You can benefit from potential
                        appreciation in property value from property owned by the business.
                    </li>
                    <li>
                        *Easier Financing Oprions*: Acquiring a going concern
                        business with property interests may make it easier to secure financing from lenders with the property providing collateral for loans, potentially offering and in some cases having its own development and expansion potential
                    </li>
                    <li>
                        *Reduced Risk*: While all investments carry some level of risk,
                        buying an established business means the buyer has the benefit of data and performance metrics to analyze, which can help  make a more informed decision on the purchase
                    </li>

                </ol>
            </div>

            <div className="article-container">
                <h2 className="article-title">Interested in Buying a Care Home Business?</h2>
                <p className="article-meta">Published on January 1, 2024</p>
                {/* <img
                    className="article-image"
                    // src="https://www.shutterstock.com/shutterstock/photos/557307181/display_1500/stock-photo-businessmen-making-handshake-in-the-city-business-etiquette-congratulation-merger-and-557307181.jpg" // Replace with your actual image URL
                    src="https://www.shutterstock.com/shutterstock/photos/246420013/display_1500/stock-photo-harbor-freight-blue-toned-images-246420013.jpg"
                    alt="Article Image"
                /> */}
                <ul className="article-content">
                    <li>
                        We provide or facilitate due diligence, assessments and financial evaluations
                    </li>
                    <li>
                        Legal transfers
                    </li>
                    <li>
                        CQC (Care Quality Commission) Accreditation  support
                    </li>
                    <li>
                        Connection to Specialist Business Finance
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default FreeHoldsPage;