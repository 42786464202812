// App.js
import React from 'react';
import HomePage from './HomePage/HomePage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Navbar/Navbar';
import FooterComponent from './FooterComponent/FooterComponent';
import TestComponent from './TestComponent/TestComponent';

import ContactPage from './Pages/ContactPage';
import FinancePage from './Pages/FinancePage';
import LegalPage from './Pages/LegalPage';
import OurBusinessPage from './Pages/OurBusinessPage';
import PropertiesPage from './Pages/PropertiesPage';
import MarketUpdatesPage from './Pages/MarketUpdatesPage';
import LuxuryHomesPage from './Pages/LuxuryHomesPage';
import FreeHoldsPage from './Pages/FreeHoldsPage';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import CookiePolicy from './Pages/CookiePolicy';

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/">
                    <Route index element={<HomePage />} />
                    <Route path="our-business" element={<OurBusinessPage />} />
                    <Route path="properties" element={<PropertiesPage />} />
                    <Route path="contact" element={<ContactPage />} />
                    <Route path="finance" element={<FinancePage />} />
                    <Route path="legal" element={<LegalPage />} />
                    <Route path="market-updates" element={<MarketUpdatesPage />} />
                    <Route path="luxury-homes" element={<LuxuryHomesPage/>} />
                    <Route path="free-holds" element={<FreeHoldsPage/>} />
                    <Route path="cookie-policy" element={<CookiePolicy/>} />
                    <Route path="privacy-policy" element={<PrivacyPolicy/>} />
                    {/* <Route path="*" element={<NoPage />} /> */}
                </Route>
            </Routes>
            <FooterComponent />
        </BrowserRouter>
    );
}

export default App;
