import React from "react";
import './ArticleStyles.css'
function LegalPage() {
    return (
        <div className="article-container">
            <h2 className="article-title">Legal</h2>
            <p className="article-meta">Published on January 1, 2024</p>
            <img
                className="article-image"
                src="https://www.shutterstock.com/shutterstock/photos/104878226/display_1500/stock-photo-decorative-scales-of-justice-in-the-library-104878226.jpg" // Replace with your actual image URL
                alt="Article Image"
            />
            <p className="article-content">Buying Property anywhere in the Inited Kimgdom is an intricate and rather detailed process. In all
                cases the buyer and seller must be represented by a UK qualified solicitor/Firm holding a current
                practising certificate and are regulated by the Solicitors Regulation Authority (STA) Solicitors mus
                also possess the required professional indemnity insurance approved by the Law Society and
                which adequately protects the buyer.
            </p>


            <p className="article-content">The transaction known as conveyancing is carried out entirely between the buyer and seller's
                solicitors. Agents or consultants introducing properties do not handle or receive purchase or any
                loan funds. We simply earn our fee.
            </p>


            <p className="article-content">The buyer's solicitor is responsible for carrying out all legal due diligence on the property, which
                includes Land registry title searches, Local authority searches, Planning & Building regulations,
                any development or other schemes likely to affect the property.
            </p>


            <p className="article-content">They also carry out water drainage and environmental searches. All these are complied and
                presented in a composite report for the buyer to consider before making the decision to proceed
                and exchange contracts.
            </p>


            <p className="article-content">The buyer also receives the report of a qualified surveyor before exchange of contracts. This is
                usually commissioned by the mortgage lender (if applicable)
            </p>

            <p className="article-content">The Solicitor also deals with all the funds in the transaction, receiving your deposit as well as the
                balance of the purchase funds from your Lender (where applicable) and paying over to the seller.
            </p>

            <p className="article-content">The Solicitor is also responsible for paying your stamp duty tax to the government after completion
                of the transaction and registering your title at the Lands Registry
            </p>

            <p className="article-content">At OneVerse, we ensure your transaction is handled by experienced property solicitors, especially
                those with experience representing buyers and investors from different jurisdictions and locations outside the UK.
            </p>
            

            <p className="article-content">We work very closely with our external solicitors following up your transaction at every stage from
                inception to completion.
            </p>

            <p className="article-content">You are always entitled as a buyer to use the solicitor of your choice. Regardless of who you
                instruct us solicitor, it is very important to note that they always work independently.
            </p>

            <p className="article-content">They are not allowed to have a business interest in your transaction. Your solicitors independence
                ensures that they can always provide impartial advice to you as a buyer
            </p>

        </div>
    )
}

export default LegalPage;