import React from "react";
import './ContentSections.css';


function PropertyManagementContent (){
    return (
        <div>
           <h2>Property Management</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et suscipit leo, vel interdum ex.
                Nullam efficitur justo vel orci congue, a feugiat libero aliquet. Vestibulum posuere mi nec
                sem fermentum, ut dignissim metus tristique. Suspendisse potenti. Duis consequat mi et lacus
                posuere, sit amet vehicula risus feugiat.
                Praesent vitae justo nec justo accumsan bibendum in ac felis. Nulla facilisi. Integer ac
                congue risus, ac tristique elit. Morbi ut elit sit amet elit tempor ullamcorper. Nam non
                nunc ut turpis euismod ullamcorper. Aenean vestibulum, lacus non fringilla tincidunt,
                ligula ligula feugiat elit, eu convallis orci tortor vel orci.
            </p>
            <p>
                Quisque non bibendum orci. Curabitur dapibus lacus sit amet nunc elementum, id aliquam dui
                ultricies. Integer vestibulum ligula nec felis tincidunt, id aliquet libero bibendum. Sed
                scelerisque, tortor eget ullamcorper dictum, libero dui consectetur mauris, eu ultrices Praesent 
                vitae justo nec justo accumsan bibendum in ac felis. Nulla facilisi. Integer ac
                congue risus, ac tristique elit. Morbi ut elit sit amet elit tempor ullamcorper. Nam non
                nunc ut turpis euismod ullamcorper. Aenean vestibulum, lacus non fringilla tincidunt,
                ligula ligula feugiat elit, eu convallis orci tortor vel orci.
                purus ligula eu urna.
            </p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et suscipit leo, vel interdum ex.
                Nullam efficitur justo vel orci congue, a feugiat libero aliquet. Vestibulum posuere mi nec
                sem fermentum, ut dignissim metus tristique. Suspendisse potenti. Duis consequat mi et lacus
                posuere, sit amet vehicula risus feugiat.
            </p>
            <p>
                Praesent vitae justo nec justo accumsan bibendum in ac felis. Nulla facilisi. Integer ac
                congue risus, ac tristique elit. Morbi ut elit sit amet elit tempor ullamcorper. Nam non
                nunc ut turpis euismod ullamcorper. Aenean vestibulum, lacus non fringilla tincidunt,
                ligula ligula feugiat elit, eu convallis orci tortor vel orci.
            </p>
            <p>
                Quisque non bibendum orci. Curabitur dapibus lacus sit amet nunc elementum, id aliquam dui
                ultricies. Integer vestibulum ligula nec felis tincidunt, id aliquet libero bibendum. Sed
                scelerisque, tortor eget ullamcorper dictum, libero dui consectetur mauris, eu ultrices
                purus ligula eu urna.
            </p>
        </div>
        
    )
}

export default PropertyManagementContent;