import React from "react";
import './ArticleStyles.css';
function PrivacyPolicy() {
    return (
        <div className="article-container">
            <h2 className="article-title">PRIVACY STATEMENT</h2>
            <p className="article-meta">Last Updated: 28th October 2023.</p>
            <p className="article-content">
                Oneverse Property Management limited ("we," "us," or "our") is committed to protecting the privacy and security of your
                personal information. This Privacy Statement explains how we collect, use, share, and protect your personal data in accordance
                with the General Data Protection Regulation (GDPR).
            </p>
            <ol className="article-content">
                <li>
                    Data Controller
                    Oneverse Property Management limited.
                    investments@oneverseproperty.co.uk

                </li>
                <li>
                    What Personal Data We Collect
                    We may collect the following types of personal data:
                    <ul>
                        <li>
                            Contact information (e.g., name, email address, phone number).
                        </li>
                        <li>
                            Demographic information (e.g., age, gender, location).
                        </li>
                        <li>
                            Payment and financial information.
                        </li>
                        <li>
                            Log and usage data (e.g., IP address, browser type, and device information).
                        </li>
                        <li>
                            Other information you voluntarily provide to us.
                        </li>

                    </ul>

                </li>
                <li>
                    How We Use Your Personal Data
                    We use your personal data for the following purposes:
                    <ul>
                        <li>
                            To provide and maintain our products and services.
                        </li>
                        <li>
                            To communicate with you and respond to your requests.
                        </li>
                        <li>
                            To process payments and transactions.
                        </li>
                        <li>
                            To improve our products and services.
                        </li>
                        <li>
                            To send you marketing and promotional materials (with your consent).
                        </li>
                        <li>
                            To comply with legal obligations.
                        </li>
                    </ul>



                </li>
                <li>
                    Legal Basis for Processing
                    We process your personal data on the following legal bases:
                    <ul>
                        <li>
                            Consent: When you provide your explicit consent for specific purposes.
                        </li>
                        <li>
                            Contractual necessity: To fulfill our contractual obligations with you.
                        </li>
                        <li>
                            Legal obligations: To comply with applicable laws and regulations.
                        </li>
                        <li>
                            Legitimate interests: Pursuing our legitimate business interests, provided they do not override your interests and fundamental rights.
                        </li>
                    </ul>

                </li>
                <li>
                    Sharing Your Personal Data
                    We may share your personal data with:
                    <ul>
                        <li>
                            Service providers and partners who help us deliver our services.
                        </li>
                        <li>
                            Legal authorities when required by law.
                        </li>
                        <li>
                            Third parties with your consent.

                        </li>
                    </ul>
                </li>

                <li>
                    International Data Transfers
                    Your personal data may be transferred to and processed in countries outside the European Economic Area (EEA). We ensure that appropriate safeguards are in place to protect your data in accordance with the GDPR.

                </li>
                <li>
                    Data Retention
                    We will retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Statement, unless a longer retention period is required by law.

                </li>
                <li>
                    Your Rights
                    You have the following rights under the GDPR:
                    <ul>
                        <li>
                            Right to access and rectify your data.
                        </li>
                        <li>
                            Right to erasure (the "right to be forgotten").
                        </li>
                        <li>
                            Right to restrict processing.
                        </li>
                        <li>
                            Right to data portability.
                        </li>
                        <li>
                            Right to object to processing.
                        </li>
                    </ul>

                    <li>
                        Right not to be subject to automated decision-making.
                    </li>
                </li>
                <li>
                    Security Measures
                    We implement technical and organizational measures to protect your personal data from unauthorized access, disclosure, and alteration.
                </li>
                <li>
                    Contact Us
                    If you have any questions or concerns about our privacy practices or wish to exercise your rights, please contact us at [Contact Information].
                </li>
                <li>
                    Updates to this Privacy Statement
                    We may update this Privacy Statement to reflect changes in our data processing practices. We will provide notice of significant updates.
                </li>
            </ol>
        </div>
    )
}

export default PrivacyPolicy;