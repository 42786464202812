import React from "react";
import './ArticleStyles.css'
function PropertiesPage(){
    return (
        <div className="article-container">
            <h2 className="article-title">Properties</h2>
            <p className="article-meta">Published on January 1, 2024</p>
            <img
                className="article-image"
                src="https://www.shutterstock.com/shutterstock/photos/189023420/display_1500/stock-photo-house-building-and-city-construction-concept-evening-outdoor-urban-view-of-modern-real-estate-homes-189023420.jpg" // Replace with your actual image URL
                alt="Article Image"
            />
            {/* <p className="article-content">
                We source, provide, and advise our clients on a range of opportunities from high-yield residential
                buy-to-let investments, small to medium developments, and other residential and commercial
                opportunities.
            </p> */}
            <p className="article-content">
                From a variety of on and off-market sources, we deliver exciting property investment opportunities
                for our clients. In many cases, especially with new-build schemes, we negotiate appealing
                incentives, like stamp duty paid and pruce discounts from leading nationwide developers.
            </p>
            <ul className="article-content"> 
                <li>Buy-to-let investments</li>
                <li>Luxury apartments</li>
                <li>Commercial Property</li>
                <li>Development Projects</li>
                <li>Hotels</li>
                <li>Care Homes</li>
                <li>Student accommodation</li>
            </ul>
        </div>
    )
}

export default PropertiesPage;