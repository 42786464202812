import React from 'react';

import BannerComponent from '../BannerComponent/BannerComponent';
// import Hero from '../Hero/Hero';
import TestComponent from '../TestComponent/TestComponent';
import AboutUs from '../AboutUs/AboutUs';
import ContentSection from '../ContentSection/ContentSection';
import FooterComponent from '../FooterComponent/FooterComponent';
import Metrics from '../Metrics/Metrics';
import Offers from '../Offers/Offers';
// import FAQ from '../FAQ/FAQ';

import StampDutyCalculator from '../StampDutyCalculator/StampDutyCalculator';


import './HomePage.css';

const img = "https://as2.ftcdn.net/v2/jpg/02/38/45/61/1000_F_238456158_p3m4uGTf05W4d78wORQdbeUo9jkTEcEt.jpg"

function HomePage() {
    return (
        <div className="home-page">
            {/* <TestComponent/> */}
            <BannerComponent />
            {/* <Hero/> */}
            <AboutUs/>
            
            <Offers/>
            {/* <Metrics/> */}
            <ContentSection />
            {/* <FAQ/> */}
            {/* <StampDutyCalculator/> */}
            {/* <FooterComponent/> */}
            
        </div>
    );
}

export default HomePage;
