import React from 'react';
import OfferCard from '../OfferComponents/OfferCard';
// import ImageOfferCard from '../OfferComponents/ImageOfferCard';
import './Offers.css';

const offer1 = {
    title : "Holiday Home Investment opportunities",
    description :"Purchase your own fully managed holiday apartment",
    details : ["exotic holiday locations", "buy on or off plan"],
    highlightPercentage : "$1800",
    highlightParagraph : "typical returns per week for a 2 bed condo",
    image: "https://www.shutterstock.com/shutterstock/photos/219887434/display_1500/stock-photo-tropical-villa-view-with-garden-swimming-pool-and-open-living-room-at-sunset-219887434.jpg"
}

const offer2 = {
    title : "Buy a Business",
    description :"Buy income generating  businesses as assets with freehold interests",
    details : ["Restaurants", "Boutique hotels", "Residential Care Homes", "Commercial & retail units"],
    image: "https://www.shutterstock.com/shutterstock/photos/538323868/display_1500/stock-photo-bar-counter-in-restaurant-interior-538323868.jpg"
}


const offer3 = {
    title : "Luxury Homes",
    description :"Exciting UK Property investment opportunities",
    details : [
        "Luxury buy-to-let apartments",
        "Penthouse apartments",
        "Eco-friendly estates",
        "Waterfront estates",
        "Custom-built mansions" 

    ],

    image: "https://www.shutterstock.com/shutterstock/photos/1681685137/display_1500/stock-photo-off-white-appealing-kitchen-with-bulk-head-1681685137.jpg"
}

const offer4 = {
    title: "Property Development",
    description: "We offer a comprehensive consultancy service for our  clients seeking viable development projects",
    details : [
        "identifying potential development opportunities.",
        "conducting thorough market research due diligence and analysis ",
        "assisting and advising our clients on a range of fnance options from private capital investment to institutional finance "
    ],
    highlightParagraph: "Feel free to reach out to us for a detailed consultation.",
    image: "https://www.shutterstock.com/shutterstock/photos/133942856/display_1500/stock-photo-modern-house-in-the-section-with-visible-infrastructure-and-interior-outline-sketch-and-rendering-133942856.jpg"
}



function Offers() {
    return (
        <div className="offers">
            <h1 className="offers-title">Overview</h1>
            <div className="offers-items">
                <OfferCard offer = {offer1} download_file={"./Rockley_Residences.pdf"}/>
                <OfferCard offer = {offer2 } link = {"free-holds"}/>
                {/* <ImageOfferCard offer = {offer1}/>
                <ImageOfferCard offer = {offer2}/>
                <ImageOfferCard offer = {offer3}/>
                <ImageOfferCard offer = {offer4}/> */}
            </div>
            <div className="offers-items">
                <OfferCard offer = {offer3 } link = {"luxury-homes"}/>
                <OfferCard offer = {offer4}/>
            </div>


        </div>
    );
}




export default Offers;
