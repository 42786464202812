import React from "react";
import './ArticleStyles.css';
function MarketUpdatesPage(){
    return (
        <div className="article-container">
            <h2 className="article-title">*New Leasehold reform laws on the way.*</h2>
            <p className="article-meta">Published on January 1, 2024</p>
            <img
                className="article-image"
                src="https://www.shutterstock.com/shutterstock/photos/62768635/display_1500/stock-vector-colored-arrows-vector-62768635.jpg" // Replace with your actual image URL
                alt="Article Image"
            />
            <p className="article-content">

If you own a Leasehold property in England or Wales and your Lease term is up for renewal, you may be well advised to wait until the new bill is passed into law; potentially saving  thousands in renewal premiums, as well as potentially gaining an extension by extra hundreds of years
            </p>
        </div>
    )
}

export default MarketUpdatesPage;