import React from "react";
import './ArticleStyles.css'
function FinancePage() {
    return (
        <div className="article-container">
            <h2 className="article-title">Finance</h2>
            <p className="article-meta">Published on January 1, 2024</p>
            <img
                className="article-image"
                src="https://www.shutterstock.com/shutterstock/photos/527098861/display_1500/stock-photo-double-exposure-of-city-and-pen-calculator-money-graph-for-finance-and-business-concept-527098861.jpg" // Replace with your actual image URL
                alt="Article Image"
            />
            <p className="article-content">
                Finance is ultimately the most important aspect of the buyer's project.
            </p>
            <p className="article-content">
                We are not a finance company, but we uniquely have key partnerships in
                finance sectors with particular focus international investors that we can refer our clients to if required.
            </p>

            <p className="article-content">
                Specialist lenders are usually required for financing buyers not
                domiciled to the UK. As an optional service, we put our
                investing clients in contact with brokers with access to bespoke
                Lender terms more applicable or particularly designed for
                clients domiciled in Asia, Africa, US and the Middle East
                who satisfy their qualification criteria.
            </p>

            <p className="article-content">
                We are continuously expanding our contact base and relationships within the UK lending market.
            </p>
        </div>
    )
}

export default FinancePage;