import React from "react";
import './ArticleStyles.css';
function LuxuryHomesPage() {
    return (
        <div className="article-container">
            <h2 className="article-title">Luxury Homes</h2>
            <p className="article-meta">Published on January 1, 2024</p>
            <img
                className="article-image"
                src="https://www.shutterstock.com/shutterstock/photos/269469479/display_1500/stock-photo-contemporary-house-exterior-on-the-gold-coast-queensland-australia-269469479.jpg" // Replace with your actual image URL
                alt="Article Image"
            />
            <p className="article-content">
                We can offer a curated selection of on and off-plan residential developments for sale, in London and Greater London, showcasing modern design, premium amenities, and breathtaking views overlooking the City.
            </p>
            <ul className="article-content">
                <li>Benefit from exclusive discounted deals and enticing incentives directly from
                    developers, including in some cases stamp duty incentives.
                </li>

                <li>
                    Experience the epitome of luxury living in the most coveted locations,
                    steps away from trendy bars, chic boutiques, world-class restaurants, and exciting new developments.
                </li>
            </ul>
        </div>
    )
}

export default LuxuryHomesPage;