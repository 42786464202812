import React from 'react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';
import './FooterComponent.css';

const info = {
    address: "128 City Road, London, United Kingdom, EC1V 2NX",
    privacy_statement: "OneVerse Property is a trading name of Oneverse Property Management Ltd. Registered Company No.15101338 © 2024. All rights reserved.",
    phone_number: "+44 79470 90715",
    email: "team@oneversepropert.co.uk",
    img: "https://i.imgur.com/OAluleg.jpeg"
}

// function Footer() {
//     return (
//         <footer className="footer">
//             <div className="footer-top">
//                 <div className="footer-content">
//                     <div className="footer-logo">
//                         {/* <img src={info.img} alt="Company Logo" /> */}
//                     </div>

//                     <div className="footer-info">
//                         <p>OneVerse Property Investments Limited</p>
//                         <p>{info.address}</p>
//                         <p>{info.phone_number}</p>
//                         <p>{info.email}</p>
//                     </div>
//                     <div className="footer-privacy">
//                         <p>{info.privacy_statement}</p>
//                     </div>
//                 </div>                
//             </div>

//             <div className="footer-social-icons">
//                 <a href="#"><BsFacebook /></a>
//                 <a href="#"><BsInstagram /></a>
//                 <a href="#"><BsTwitter /></a>
//             </div>
//         </footer>
//     );
// }

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer">
                <div className="footer-section">
                    <div className="divider-line info-section-divider"></div>
                    <div className="footer-info">
                        <div className="footer-heading">Oneverse Property Management Ltd</div>
                        <p>{info.address}</p>
                        <p>{info.phone_number}</p>
                        <p>{info.email}</p>
                    </div>
                </div>

                <div className="footer-section">
                    <div className="divider-line links-section-divider"></div>
                    <div className="footer-links">
                        <div className="footer-heading">Legal</div>
                        <div className="footer-links-links">
                            <a href="/cookie-policy">Cookie Policy</a>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </div>

                    </div>
                </div>

                <div className="footer-section">
                    <div className="divider-line"></div>
                    <div className="footer-privacy">
                        <p>{info.privacy_statement}</p>
                    </div>

                </div>


            </div>


            <div className="footer-social">
                <a href="#"><BsFacebook /></a>
                <a href="#"><BsInstagram /></a>
                <a href="#"><BsTwitter /></a>
            </div>
        </div>

    )
}

export default Footer;
