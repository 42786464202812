import React from 'react';
import './AboutUs.css';
import { useRef, useEffect } from "react";
function AboutUs() {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })
    return (
        <div className="about-us-container">
            <div className="about-us-image-container">
                <h2>Our Business</h2>
                {/* <img 
                    // src="https://pillarmanagement.ca/images/f/4/5/5/3/f4553d494cf3bf231ea4c44d038ffba2b69c273a-property-management-rental-building.jpg?quaity=60"
                    alt="Property Management"
                /> */}

                <video 
                    ref={videoRef}
                    loop
                    autoPlay
                    muted
                    playsInline
                    className = "about-us-video">
                    <source src="https://www.shutterstock.com/shutterstock/videos/1046335003/preview/stock-footage-uhd-sunset-timelapse-of-the-millennium-bridge-with-st-paul-cathedral-and-crowd-of-tourists-and.webm" type="video/webm" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="about-us-text-container">
                <h2>Our Business</h2>
                <p>
                    We are a property investment consultancy. OneVerse Property primarily advises and facilitates
                    services for international clients looking to diversify their portfolio by investing in the UK and international property markets.
                </p>
                <p>
                    We bring to the investing client, in-depth industry knowledge and expertise in the UK property market,
                    combined years of transactional experience and facilitating international property investment in the UK
                    from different parts of the Globe.
                </p>
                <p>
                    OneVerse consists mainly of a few London based consultants with exceptional property industry 
                    experience as well as consultant/associates working from other continents to provide a coordinated
                    liaison with our clients located around the Globe.Our clients are typically individuals, small to 
                    medium investment consortiums and cooperatives.
                </p>
                <p>
                    The uniqueness of our service lies in our involvement in the entire process; from identifying the opportunity, carrying out
                    due diligence and market analysis, negotiating with vendors, liaising and connecting clients with funding sources, and working
                    closely with the legal services of an independent property solicitor to represent you, our clients in the transaction.
                </p>
                <p>
                    Our objective is to simplify the acquisition and disposal process for our clients; create/maximize income thereby enabling them to
                    realize their investment objectives.
                </p>
            </div>
        </div>
    );
}

export default AboutUs;
