import { TypeAnimation } from 'react-type-animation';

import React from "react";
import './BannerComponent.css';
import { useRef, useEffect } from "react";
// const video1 = "/img/time-lapse.webm"
const video1 = "https://www.shutterstock.com/shutterstock/videos/1067878640/preview/stock-footage-hyperlapse-time-lapse-of-construction-city-construction-of-a-large-area-with-skyscrapers-process.mp4"

function BannerComponent() {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })
    return (
        <div className="banner">
            <div className="banner-textSection">
                <div className="banner-logo">
                    <img src="https://i.imgur.com/OAluleg.jpg" alt="Logo" />
                </div>
                <h1 className="banner-title">Elevate your property vision</h1>

                <TypeAnimation
                    sequence={[
                        // Same substring at the start will only be typed out once, initially
                        'We simplify the acquisition process',
                        1000, // wait 1s before replacing "Mice" with "Hamsters"
                        'Benefit from in-depth market expertise and industry knowledge',
                        1000,
                        'We create value for our clients',
                        1000,
                        'Maximised returns on property investment',
                        1000
                    ]}
                    wrapper="p"
                    speed={50}
                    className="banner-content"
                    repeat={Infinity}
                />
            </div>

            <div className="banner-imageSection">
                <video
                    ref={videoRef}
                    loop
                    autoPlay
                    muted
                    playsInline
                    className="banner-video" controls={false}>
                    <source src={video1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    )
}

export default BannerComponent;