import React, { useState } from "react";
import './ContentSection.css';

import EditorialContent from "../ContentSections/EditorialContent";
import FAQContent from "../ContentSections/FAQContent";
import FAQ from "../FAQ/FAQ";
import MarketUpdatesContent from "../ContentSections/MarketUpdatesContent";
import PropertyManagementContent from "../ContentSections/PropertyManagementContent";

const ContentMap = {
    "EditorialContent": <EditorialContent />,
    "FAQContent": <FAQ />,
    "MarketUpdatesContent": <MarketUpdatesContent />,
    "PropertyManagementContent": <PropertyManagementContent />,
}

function ContentSection() {
    const [content, setContent] = useState(ContentMap["EditorialContent"]);

    const changeContentType = (button) => {
        setContent(ContentMap[button]);
    };
    
    return (
        <div className="content-section" id = "content-section">
            <div className='right-column-container'>
                    <ul className = "link-list">
                        <li onClick={() => changeContentType("FAQContent")}>FAQs</li>
                        <li onClick={() => changeContentType("MarketUpdatesContent")}>Market Updates</li>
                        <li onClick={() => changeContentType("EditorialContent")}>Editorial</li>
                    </ul>
            </div>
            <div className="content">
                {content}
            </div>
            
        </div>
    )
}


export default ContentSection;
