import React from "react";
import './ArticleStyles.css'
function OurBusinessPage() {
    return (
        <div>


            <div className="article-container">
                <h2 className="article-title">Our Business</h2>
                <p className="article-meta">Published on January 1, 2024</p>
                <video 
                    loop
                    autoPlay
                    muted
                    playsInline
                    className = "article-image">
                    <source src="https://www.shutterstock.com/shutterstock/videos/1046335003/preview/stock-footage-uhd-sunset-timelapse-of-the-millennium-bridge-with-st-paul-cathedral-and-crowd-of-tourists-and.webm" type="video/webm" />
                    Your browser does not support the video tag.
                </video>
                <p className="article-content">
                    We are a property investment consultancy. OneVerse Property primarily advises and facilitates
                    services for international clients looking to diversify their portfolio by investing in the UK and international property
                    markets.
                </p>
                <p className="article-content">
                    We bring to the investing client, in-depth industry knowledge and expertise in the UK property market,
                    combined years of transactional experience and facilitating international property investment in the UK
                    from different parts of the Globe.
                </p>

                <p className="article-content">
                    Our clients are typically individuals, small to medium investment consortiums and cooperatives. OneVerse consists mainly
                    of a few London based consultants with exceptional property industry experience as well as consultant/associates working
                    from other continents to provide a coordinated liason with our clients located around the Globe.
                </p>

                <p className="article-content">
                    The uniqueness of our service lies in our involvement in the entire process; from identifying the opportunity, carrying out
                    due diligence and market analysis, negotiating with vendors, liaising and connecting clients with funding sources, and working
                    closely with the legal services of an independent property solicitor to represent you, our clients in the transaction.
                </p>

                <p className="article-content">
                    We also provide a contract management service overseeing project management and other key services like compliance and planning
                    approvals where the acquisition is for purposes of development/redevelopment and disposal of the asset.
                </p>

                <p className="article-content">
                    Our objective is to simplify the acquisition and disposal process for our clients; create/maximise income thereby enabling them to
                    realise their investment objectives.
                </p>
            </div>
            <div className="article-container"><h2 className="article-title">Complex and Special Transactions </h2>

                <p className="article-content">Oneverse Property offers expertise that goes well beyond traditional estate agency services.</p>

                <p className="article-content">With several years of transactional experience, our consultancy has advised clients in structuring and advising on complex and intricate property transactions.</p>

                <p className="article-content">The company's consultancy encompasses, development projects, sale and leaseback transactions, Lease Options, cross-border transactions, joint ventures, distressed property transactions, </p>

                <p className="article-content">We not only have an eye for detail, but we work with regulated professional teans well-versed in navigating the legal, financial, and regulatory complexities of every transaction.</p>
            </div>
            <div className="article-container">
                <h2 className="article-title">How We Work</h2>

                <p className="article-content">Oneverse Property's consultative approach involves working closely with clients to understand their specific requirements and objectives. </p>

                <p className="article-content">To property owners and buyer/investors alike, we provide  bespoke tailored rategies that maximize value and mitigate risks in each transaction. </p>

                <p className="article-content">We leverage on our deep industry knowledge and network of contacts, to assist clients in achieving their real estate investment goals and objectives.</p>
            </div>
            
        </div>


    )
}

export default OurBusinessPage;
