import React from "react";
import "./OfferCard.css"; // Import your CSS file for GitHub style

function OfferCard({ offer, download_file, link}) {
  const details = offer.details.map((detail, index) => (
    <li key={index}>{detail}</li>
  ));

  const handleClick = () => {
    if (download_file !== undefined) {
      // If download_file is provided, initiate file download
      const link = document.createElement('a');
      link.href = download_file;
      const download_file_split = download_file.split('/');
      link.download = download_file_split[download_file_split.length - 1];
      link.click();
    } else if (link !== undefined) {
      // If link is provided, navigate to the specified link
      window.location.href = link;
    }
  };

  return (
    <div className="image-offer-card" onClick={handleClick}>
      <div className="image-offer-card-header">
        <div className="image-offer-card-image">
          <img src={offer.image} alt={offer.title} />
        </div>
        <h2 className="image-offer-card-title">{offer.title}</h2>
        
        <p className="image-offer-card-description">{offer.description}</p>
      </div>
      <div className="image-offer-card-details">
        <ul>{details}</ul>
      </div>
      <div className="image-offer-card-highlight">
        <p className="image-offer-card-highlight-percentage">
          {offer.highlightPercentage}
        </p>
        <p className="image-offer-card-highlight-paragraph">
          {offer.highlightParagraph}
        </p>
      </div>
    </div>
  );
}

export default OfferCard;
