import React from "react";
import './ContentSections.css';


function MarketUpdatesContent (){
    return (
        <div className = "content-sections">
            <h2>New Leasehold reform laws on the way.</h2>
            <img
                className="article-image"
                src="https://www.shutterstock.com/shutterstock/photos/62768635/display_1500/stock-vector-colored-arrows-vector-62768635.jpg" // Replace with your actual image URL
                alt="Article Image"
            />
            <p>
                If you own a Leasehold property in England or Wales and your Lease term is up for renewal, you may be well advised to wait until the new bill is passed into law; potentially saving  thousands in renewal premiums, as well as potentially gaining an extension by extra hundreds of years
            
            </p>
        </div>
        
    )
}

export default MarketUpdatesContent;