import React from "react";
import './ArticleStyles.css';
function CookiePolicy() {
    return (
        <div className="article-container">
            <h2 className="article-title">Cookie Policy</h2>
            <p className="article-meta">Last Updated: 28th October 2023.</p>
            <p className="article-content">
                This website uses cookies to improve the user experience. By using this website,
                you agree to our use of cookies in accordance with this policy.
            </p>
            <p className="article-subheading">
                What are cookies?

            </p>

            <p className="article-content">
                Cookies are small text files that are stored on your device by websites that you visit.
                They are widely used to make websites work more efficiently and to provide information
                to the owners of the site.
            </p>

            <p className="article-subheading">
                How we use cookies

            </p>

            <p className="article-content">
                We use cookies for a variety of purposes, including:
            </p>
                <ol>
                    <li>
                        Essential cookies: These cookies are necessary for the website to function properly.

                    </li><li>
                        Analytics cookies: These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.

                    </li><li>
                        Marketing cookies: These cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user.

                    </li>
                </ol>
            

            <p className="article-subheading">
                Managing cookies

            </p>

            <p className="article-content">
                You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.
            </p>

            <p className="article-subheading">
                Changes to this policy

            </p>

            <p className="article-content">
                We reserve the right to update this cookie policy at any time. Please check this page periodically for changes. Your continued use of this site after any changes to this policy will constitute your acceptance of such changes.
            </p>

            <p className="article-subheading">
                Contact us

            </p>

            <p className="article-content">
                If you have any questions about our cookie policy, please contact us at team@oneverseproperty.co.uk </p>



        </div>
    )
}

export default CookiePolicy;